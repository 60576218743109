<template>
    <div class="Reports full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 flex-grow-1 bg-white">
                <v-row class="d-flex align-center justify-space-between mb-5 filter">
                    <v-col cols="5" class="d-flex align-center justify-space-between pl-0">
                        <p>{{ $t('general.search') }}:</p>
                        <textinput inputName="reportSearch" class="ml-6" @onInputFocus="onInputFocus"></textinput>
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-space-between pr-0">
                        <p>{{ $t('general.from') }}:</p>
                        <v-dialog ref="datedialogfrom" v-model="datemodalfrom" :return-value.sync="searchDateFrom"
                                  persistent width="350px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchDateFromFormatted"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    class="ml-6 mr-6 is-modal"
                                    id="datemodalfrom"

                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="searchDateFrom"
                                scrollable
                                :show-current="false"
                                header-color="disiDarkblue"
                                color="disiIrisblue"
                                :locale=langSelection
                                first-day-of-week="1"
                                width="350"
                                id="datemodalfrom-datepicker"
                            >
                                <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                       @click="$refs.datedialogfrom.save('')">{{ $t('footerButton.cancel') }}
                                </v-btn>
                                <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                       @click="$refs.datedialogfrom.save(searchDateFrom)">{{ $t('footerButton.save') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <p>{{ $t('general.to') }}:</p>
                        <v-dialog ref="datedialogto" v-model="datemodalto" :return-value.sync="searchDateTo" persistent
                                  width="350px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchDateToFormatted"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    class="ml-6 is-modal"
                                    id="datemodalto"

                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="searchDateTo"
                                scrollable
                                :show-current="false"
                                header-color="disiDarkblue"
                                color="disiIrisblue"
                                :locale=langSelection
                                first-day-of-week="1"
                                width="350"
                                id="datemodalto-datepicker"
                            >
                                <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                       @click="$refs.datedialogto.save('')">{{ $t('footerButton.cancel') }}
                                </v-btn>
                                <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                       @click="$refs.datedialogto.save(searchDateTo)">{{ $t('footerButton.save') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="reportsTableHeaders"
                    :items="reportlist"
                    :search="reportSearch"
                    :sort-by="['dateTime']"
                    :custom-sort="customSort"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="(item) in items" :key="item.id" :id="item.id" :class="{'selected': selectedId.includes(item.id)}" @click="select(item.id)">
                                <td>{{ item.productName }}</td>
                                <td>{{ item.productNumber }}</td>
                                <td>{{ item.batchName }}</td>
                                <td>{{ item.batchSequenceNumber }}</td>
                                <td>{{ item.dateTime }}</td>
                                <td class="show"></td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="printAll" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printAll" :class="{'disabled': !checkUserRights('reportsPrint')}"></footer-button>
            <footer-button buttonFunction="delete" :successCheck="showDeleteSuccess" :progress="showDeleteProgress"  :class="{'disabled': selectedId.length < 1 || !checkUserRights('reportsDelete')}" @footerButtonClick="deleteReport"></footer-button>
            <footer-button buttonFunction="open" :class="{'disabled': selectedId.length != 1 || !checkUserRights('reports')}" @footerButtonClick="openRow()"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import Textinput from "@/components/TextInput.vue";
import moment from 'moment'

export default {
    name: 'Report',
    components: {
        Textinput,
        FooterButton,
    },
    props: {},
    data() {
        return {
            selectedId: [],
            showPrintProgress: false,
            showPrintSuccess: false,
            showDeleteProgress: false,
            showDeleteSuccess: false,
            datemodalfrom: false,
            searchDateFrom: '',
            searchDateFromFormatted: '',
            datemodalto: false,
            searchDateTo: '',
            searchDateToFormatted: '',
        }
    },

    computed: {
        ...mapState([
            'reportlist',
            'methodlist',
            'medialist',
            'productlist',
            'batchlist',
            'systemsettings',
            'reportSearch',
        ]),
        langSelection() {
            if (this.systemsettings.language === 'Deutsch') {
                return 'de-DE'
            } else {
                return 'en-GB'
            }
        },
        reportsTableHeaders() {
            return [
                {text: this.$t('products.productName'), align: 'start', value: 'productName'},
                {text: this.$t('products.number'), value: 'productNumber', filterable: false},
                {text: this.$t('report.batchName'), align: 'start', value: 'batchName'},
                {text: this.$t('general.test'), align: 'start', value: 'batchSequenceNumber', filterable: false},
                {
                    text: this.$t('general.date'), align: 'start', value: 'dateTime',
                    //additional filter for date
                    filter: value => {
                        if (!this.searchDateFrom && !this.searchDateTo) {
                            return true
                        }

                        let filterValue = moment(value, 'DD.MM.YYYY hh:mm');

                        let filterTo, filterFrom;
                        if (this.searchDateTo) {
                            filterTo = moment(this.searchDateTo, 'YYYY-MM-DD');
                        }

                        if (this.searchDateFrom) {
                            filterFrom = moment(this.searchDateFrom, 'YYYY-MM-DD');
                        }

                        if (filterValue.isAfter(filterFrom) && !filterTo) {
                            return true;
                        }

                        if (filterValue.isBefore(filterTo) && !filterFrom) {
                            return true;
                        }

                        if (filterValue.isAfter(filterFrom) && filterValue.isBefore(filterTo)) {
                            return true;
                        }

                        return false;

                    },
                },
                {text: "", sortable: false,}
            ]
        },

    },
    methods: {
        moment: function (date) {
            return moment(date);
        },
        select(id){
            let contains = this.selectedId.find(function(item){
                return item == id;
            });
            if (contains){
                let removed = [];
                this.selectedId.forEach(selection =>{
                    if (selection != id){
                        removed.push(selection);
                    }
                })
                this.selectedId = removed;
            } else {
                this.selectedId.push(id);
            }
        },
        openRow() {
            this.$store.dispatch('postAxiosSetter', ['/disi/reports/details', {id: this.selectedId[0]}])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.commit('changeReport', response.data);
                    }
                });
        },
        deleteReport(){
            this.showDeleteProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/delete', {'id': this.selectedId}])
                .then(response=>{
                    this.showDeleteProgress = false;
                    this.selectedId = [];
                    this.$store.dispatch('getAxiosSetter', ['/disi/reports/list', 'reportlist']);
                    if (response.status === 200){
                        this.showDeleteSuccess = true;
                        setTimeout(() => {
                            this.showDeleteSuccess = false;
                        }, 800)
                    }
                })
        },
        printAll() {
            this.showPrintProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/disi/reports/printAll')
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },

        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] === 'dateTime') {
                    if (!isDesc[0]) {
                        return moment(b[index], 'DD.MM.YYYY hh:mm').toDate() - moment(a[index], 'DD.MM.YYYY hh:mm').toDate();
                    } else {
                        return moment(a[index], 'DD.MM.YYYY hh:mm').toDate() - moment(b[index], 'DD.MM.YYYY hh:mm').toDate();
                    }
                } else {
                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        } else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        }
    },
    mixins: [mixins],
    created() {
        this.selectedId = [];
        this.$store.dispatch('getAxiosSetter', ['/disi/reports/list', 'reportlist']);
    },
    watch: {
        searchDateFrom: function () {
            this.searchDateFromFormatted = this.searchDateFrom ? mixins.methods.userDateFormat(this.searchDateFrom) : '';
        },
        searchDateTo: function () {
            this.searchDateToFormatted = this.searchDateTo ? mixins.methods.userDateFormat(this.searchDateTo) : '';
        }
    }
}
</script>
<style scoped lang="scss">


</style>